
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Coin from './Coin.vue'
import store from '../store/index'
import History from '@/components/History.vue'

@Options({
  components: {
    History,
    Coin,
  },
  store: store
})
export default class CoinFlip extends Vue {
  @Prop() msg!: string
  coinState: 'T' | 'H' | null = null

  flipCoin () {
    this.coinState = Math.random() > 0.5 ? 'T' : 'H'
    store.dispatch('add', this.coinState)
  }

  reset () {
    store.dispatch('reset')
  }
}
