import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05ae5a2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "coin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chance = _resolveComponent("Chance")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Chance),
    (_ctx.coinState)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.coinState), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}