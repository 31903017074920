
import { Options, Vue } from "vue-class-component";
import CoinFlip from "./components/CoinFlip.vue";
import sampleMixin from '@/mixins/sampleMixin'

@Options({
  components: {
    CoinFlip,
  },
  mixins: [sampleMixin],
})
export default class App extends Vue {
  msg = 'Flip a coin and test your fortune'

  mounted() {
    sampleMixin.methods.hello()
  }
}
