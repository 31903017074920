
import store from '@/store'
import { Options, Vue } from 'vue-class-component'

@Options({
  computed: {
    chance: () => store.getters.chance
  }
})

export default class Chance extends Vue {

}
