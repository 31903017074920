export default {
    state: {
        results: [],
        scrollState: 0
    },
    mutations: {
        add (state: StoreBState, payload: string) {
            state.results.push(payload)
        },
        reset (state: StoreBState) {
            state.results = []
        },
        scrollReset(state: StoreBState) {
            state.scrollState = 0
        }
    },
    actions: {
        add (context: any, payload: string) {
            context.commit('add', payload)
            context.commit('scrollReset')
        },
        reset (context: any) {
            context.commit('reset')
        }
    },
    getters: {
        results: (state: StoreBState) => state.results,
        lastState: (state: StoreBState) => state?.results?.slice()[0] ?? null,
        chance: (state: StoreBState) => ({
            heads: state.results.length ? (state.results.filter(s => s === 'H').length / state.results.length * 100)
                .toFixed(2) : 0,
            tails: state.results.length ? (state.results.filter(s => s === 'T').length / state.results.length * 100)
                .toFixed(2) : 0
        })
    },
    modules: {}
}

interface StoreBState {
    results: string[],
    scrollState: number
}
