import { createStore } from 'vuex'
import storeB from './storeb'
import storeA from './storea'

export default createStore({
    modules: {
        storeA: storeA,
        storeB: storeB
    },
})
