
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import store from '../store/index'
import Chance from '@/components/Chance.vue'

@Options({
  components: {
    Chance
  }
})
export default class Coin extends Vue {
  @Prop() coinState!: 'H' | 'T' | null

  updated () {
    if (!this.coinState && store.getters.results.length) {
      this.coinState = store.getters.lastState
    }
  }
}
