
import { Options, Vue } from 'vue-class-component'
import store from '../store/index'

@Options({
  store: store,
  computed: {
    results: () => store.getters.results
  }
})
export default class History extends Vue {
}
