import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-4" }
const _hoisted_2 = { class: "container is-flex is-flex-direction-column coin" }
const _hoisted_3 = { class: "container flex-zero" }
const _hoisted_4 = {
  class: "mt-4",
  ref: "historyDiv"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Coin = _resolveComponent("Coin")!
  const _component_History = _resolveComponent("History")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(this.msg), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Coin, { "coin-state": _ctx.coinState }, null, 8, ["coin-state"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "button is-info",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.flipCoin && _ctx.flipCoin(...args)))
      }, "Flip coin"),
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
      }, "Clear results")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_History)
    ], 512)
  ], 64))
}