import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "history-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.slice().reverse(), (res, index) => {
      return (_openBlock(), _createElementBlock("div", { key: res }, [
        _createElementVNode("span", null, _toDisplayString((index + 1) + '. ' + (res === 'H' ? 'Heads' : 'Tails')), 1)
      ]))
    }), 128))
  ]))
}